import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import { getUserLangKey } from 'ptz-i18n'

interface Window {
  ___replace: (target: string) => void
}

declare var window: Window

export default class RedirectIndex extends React.PureComponent {
  constructor(props: any) {
    super(props)

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const { langs, defaultLangKey } = props.data.site.siteMetadata.languages
      const langKey = getUserLangKey(langs, defaultLangKey)
      const targetURL = `/${langKey}/home`

      // I don`t think this is the best solution
      // I would like to use Gatsby Redirects like:
      // https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redirects
      // But Gatsby Redirects are static, they need to be specified at build time,
      // This redirect is dynamic, It needs to know the user browser language.
      // Any ideias? Join the issue: https://github.com/angeloocana/gatsby-starter-default-i18n/issues/4
      navigate(targetURL, {
        replace: true,
        state: {
          overwriteLandingPage: true,
        },
      })
    }
  }

  public render() {
    return <div />
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
